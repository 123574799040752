import React from 'react'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

const Comment = (post) => {
  let disqusConfig = {
    url: `https://minamo173.com/blog/${post.slug}`,
    identifier: post.id,
    title: post.title,
  }
  return (
    <>
      {/* <CommentCount config={disqusConfig} placeholder={'...'} /> */}
      <Disqus config={disqusConfig} style={{ padding: '10px' }} />
    </>
  )
}

export default Comment
