import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import { Button, Grid, Typography } from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import Comment from './comment'
import AboutMe from '../components/aboutme'
import LatestPosts from './latest-posts';
import Iframely from './../components/iframely'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <Iframely/>
        <div style={{ background: '#fff' }}>
          <Helmet title={`${post.title} | ${siteTitle}`} />
          <Helmet>
            <meta property="og:description" content={post.description.children[0].internal.content} />
            <meta property="og:title" content={`${post.title} | ${siteTitle}`}/>
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`https://minamo173.com/blog/${post.slug}`} />
            <meta property="og:image" content={`https:${post.heroImage.fluid.src}`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@minamo173" />
            <meta name="twitter:creator" content="@minamo173" />
            <script async src="https://cdn.embedly.com/widgets/platform.js" charset="UTF-8"></script>
          </Helmet>
          <div className="wrapper">
            <h1 className="section-headline">{post.title}</h1>
            <Grid container style={{ marginBottom: 5 }}>
              <CalendarTodayIcon style={{ fontSize: '1rem', marginRight: 3 }}/>
              <Typography style={{ fontSize: '0.85rem' }}>{post.publishDate}</Typography>
            </Grid>
          <div>
            {post.tags &&
              post.tags.map(tag => (
                <Link to={`/tags/${tag}`} key={tag} style={{ fontWeight: 'normal', textDecoration: 'none' }}>
                  <Button variant='outlined' style={{ backgroundColor: '#d4d4d4', marginRight: 5, textTransform: 'none' }}>
                    {tag}
                  </Button>
                </Link>
              ))}
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
          </div>
          <Comment post={post}/>
          <AboutMe/>
          <LatestPosts/>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "YYYY/MM/DD")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          src
        }
      }
      slug
      tags
      body {
        childMarkdownRemark {
          html
        }
      }
      description {
        children {
          internal {
            content
          }
        }
      }
    }
  }
`
