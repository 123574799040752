import React from "react"
import { useLatestPosts } from "../hooks/latest-posts"
import { Divider, List, ListItemText, Paper, Typography } from '@material-ui/core'
import { Link } from "gatsby";

export default function LatestPosts() {
  const latestPosts = useLatestPosts();
  return (
    <Paper elevation={0} square style={{ padding: 10 }}>
      <Typography style={{ fontWeight: 'bold' }}>最新記事</Typography>
      <List>
      { latestPosts.map((p, index) => 
          <div key={index}>
            <Link to={'/blog/' + p.node.slug}>
              <ListItemText 
                primary={<Typography style={{ fontSize: '0.8rem' }}>{p.node.publishDate}</Typography>}
                secondary={<Typography style={{ fontSize: '0.9rem' }}>{p.node.title}</Typography>}
                />
            </Link>
            { index < latestPosts.length && <Divider/> }
          </div>
        )
      }
      </List>
    </Paper>
  )
}
