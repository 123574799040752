import React from "react"
import { Avatar, Grid, IconButton, Link, Paper, Typography } from '@material-ui/core'
import * as icon from './../../static/minamo173.png'
import { Instagram, Twitter, YouTube } from "@material-ui/icons"

export default function AboutMe() {
  return (
    <Paper style={{ padding: 10 }}>
      <Typography style={{ fontWeight: "bold", paddingBottom: 5 }}>プロフィール</Typography>
      <Grid container wrap="nowrap" alignItems="center" spacing={1}>
        <Grid item>
          <Avatar src={icon}/>
        </Grid>
        <Grid item>
          <Typography component='div'>
            minamo
          </Typography>
          <Typography variant='caption' component='div'>
            主にWeb開発をしているITエンジニア。旅行、写真撮影、読書などが趣味。
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Link href="https://twitter.com/minamo173" target="_blank">
          <IconButton>
            <Twitter fontSize="small"/>
          </IconButton>
        </Link>
        <Link href="https://www.instagram.com/minamo173/" target="_blank">
          <IconButton>
            <Instagram fontSize="small"/>
          </IconButton>
        </Link>
        <Link href="https://www.youtube.com/channel/UCTQ5ytsB4SHA7vZRiSua3eg" target="_blank">
          <IconButton>
            <YouTube fontSize="small"/>
          </IconButton>
        </Link>
      </Grid>
    </Paper>
  )
}
